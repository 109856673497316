// app.js

import 'bootstrap/dist/js/bootstrap.bundle.min';
// Import jQuery and assign it to the global window object
import $ from 'jquery';
// Import jQuery Validation
import 'jquery-validation';

// Import jQuery Cookie
import 'jquery.cookie';

// Importing files from the root js directory
import './bigSlide.js';
import './cookies.js';
import './function-lib.js';
import './header.js';
import './phone-validate.js';
import './script.js';
import './scrivici.js';
import images from './importImages';

// Importing files from the assistenza subdirectory
import './assistenza/function-lib.js';
import './assistenza/main.js';
import './assistenza/phone-validate.js';
import './assistenza/script.js';
import './assistenza/scrivici.js';

window.jQuery = $;
window.$ = $;
