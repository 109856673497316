import $ from 'jquery';

window.jQuery = $;
window.$ = $;
$(document).ready(function () {
    if ($('#scriviciForm').length) {

        $.validator.addMethod("fiscalcode", function (value) {
            value = value.toUpperCase();
            var regex = /[A-Z]{6}[\d]{2}[A-Z][\d]{2}[A-Z][\d]{3}[A-Z]/;
            return value.match(regex);
        }, "");

        var error;
        var dynamicErrorMsg = function () {
            return error;
        };

        $.validator.addMethod("phone",
            function (phone_number, element, params) {
                var data = 'number=' + encodeURIComponent(phone_number);
                var response = false;
                params['error'] = 'Numero di telefono non valido';
                $.ajax({
                    url: 'https://tools.alchemylab.net/api/phone',
                    data: data,
                    dataType: "json",
                    async: false,
                    success: function (json) {
                        error = (json.value) ? '' : 'Numero non valido';
                        response = json.value;
                    },
                    error: function (xhr, status, errorThrown) {
                        console.log("Error: " + errorThrown);
                        console.log("Status: " + status);
                        console.dir(xhr);
                        response = false;
                        error = errorThrown;
                    }
                });
                return response;
            },
            dynamicErrorMsg
        );

        $('#scriviciForm').validate({

            errorElement: 'div',
            rules: {
                // simple rule, converted to {required:true}
                'scrivici_name': 'required',
                'scrivici_surname': 'required',
                'scrivici_fiscalcode': {required: true, fiscalcode: true},
                'scrivici_email': {required: true, email: true},
                'scrivici_telephone': {required: true, phone: true},
                'scrivici_question': 'required',
                'scrivici_privacy': 'required'
            },
            messages: {
                'scrivici_name': 'Campo obbligatorio',
                'scrivici_surname': 'Campo obbligatorio',
                'scrivici_fiscalcode': {
                    required: "Campo obbligatorio",
                    fiscalcode: "Formato Codice Fiscale non corretto"
                },
                'scrivici_email': {
                    required: "Campo obbligatorio",
                    email: "Formato sbagliato"
                },
                'scrivici_telephone': {
                    required: "Campo obbligatorio",
                    phone: "Formato sbagliato"
                },
                'scrivici_question': 'Campo obbligatorio',
                'scrivici_privacy': 'Campo obbligatorio'
            },
            errorPlacement: function (error, element) {
                if (element.is(':checkbox')) {
                    error.insertAfter(element.closest('.custom-control').find('.custom-control-description'));
                }
                else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function (form) {
                if (grecaptcha.getResponse() == '') {
                    alert('Spuntare casella "Non sono un robot".');
                }
                else {
                    form.submit();
                }
            }

        });

    }
});
