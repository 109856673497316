/*
 * script.js
 */
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
$(document).ready(function () {
    "use strict"; //JSHINT

    // Dichiarazione tooltip bootstrap
    $('[data-toggle="tooltip"]').tooltip();

    // Dichiarazione popover bootstrap
    $('[data-toggle="popover"]').popover();

    // SmoothScroll
    $('.smooth-scroll').click(function () {
        $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top}, 500);
        return false;
    });

    /* Funzione per il cambio icona a destra dell'accordion */
    $('.collapse').on('shown.bs.collapse', function (e) {
        $(this).prev('.row').find('.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        $(this).prev('.panel-heading').find('.fa-plus').removeClass('fa-plus').addClass('fa-minus');
        e.stopPropagation();
    }).on('hidden.bs.collapse', function () {
        $(this).prev('.row').find('.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        $(this).prev('.panel-heading').find('.fa-minus').removeClass('fa-minus').addClass('fa-plus');
        e.stopPropagation();
    });


    /* Funzione per cambiare accordion da select in pagina FAQ */
    if ($('.faq__tabs').length) {
        //$("#tabNav").select2();
        $("#tabNav").change(function () {
            $('.faq__tabs__navigator button[data-bs-target="#' + $(this).val() + '"]').tab('show')
        });
    }
    var $error_message = $('.errors__message');
    if ($error_message.length)
        var sticky = new Waypoint.Sticky({
            element: $error_message[0]
        });

});
