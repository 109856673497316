/*
 * main.js
 */

import $ from 'jquery';
import Cookies from 'js-cookie';

window.jQuery = $;
window.$ = $;

$(document).ready(function () {
    // Definizione variabili
    const cookieName = 'cookie_bar';
    const cookie_expiring = 7;
    const cookiePath = '';
    //const cookies_expiring_minute = 2;

    var getCookie = Cookies.get(cookieName);
    if (!getCookie) {
        setTimeout(function () {
            var $cookiesBar = $('#cookiesBar');
            var $cookieButton = $('#cookieButton');
            $('main').prepend($cookiesBar);
            $cookiesBar.collapse('show');
            $cookieButton.click(function () {
                $cookiesBar.collapse('hide');
                //var cookie_expiring_date = new Date(new Date().getTime() + cookies_expiring_minute * 60 * 1000);
                Cookies.set(cookieName, '1', {expires: cookie_expiring, path: cookiePath});
            });
        }, 600);
    }
    $('.open-mobile-nav').bigSlide({
        'menu': '#mobile-nav',
        'push': '.push-by-mobile-nav',
        'side': 'right',
        'menuWidth': '260px',
        'speed': '600',
        'easyClose': true
    });
    /**/

    var error;
    var dynamicErrorMsg = function () {
        return error;
    };

    $.validator.addMethod("phone",
        function (phone_number, element, params) {
            var data = 'number=' + encodeURIComponent(phone_number);
            var response = false;
            params['error'] = 'Numero di telefono non valido';
            $.ajax({
                url: 'https://tools.alchemylab.net/api/phone',
                data: data,
                dataType: "json",
                async: false,
                success: function (json) {
                    error = (json.value) ? '' : 'Numero non valido';
                    response = json.value;
                },
                error: function (xhr, status, errorThrown) {
                    console.log("Error: " + errorThrown);
                    console.log("Status: " + status);
                    console.dir(xhr);
                    response = false;
                    error = errorThrown;
                }
            });
            return response;
        },
        dynamicErrorMsg
    );

    $('#form').validate({
        rules: {
            // simple rule, converted to {required:true}
            'number': {
                required: true,
                phone: true
            }
        },
        messages: {
            'number': {
                required: "Campo obbligatorio"
            }
        },
        submitHandler: function (form, event) {
            event.preventDefault();
        }
    });
});
